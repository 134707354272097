import React, { useState } from 'react';

import Wrapper from '../Wrapper';
import Icon from '../Icon';
import Text from '../Text';

import withStyles from './styles';
import { colors } from '../../assets/theme';

const Modal = ({ close, children, contentStyle, title = false, ...props }) => {
    return (
        <Wrapper {...props}>
            <Wrapper flexbox column className='content' style={contentStyle || {}}>
                {title && (
                    <Wrapper
                        flexbox
                        bgColor='background.05'
                        position='relative'
                        center
                        width='100%'
                        padding='0.5rem'
                        boxShadow='2px 2px 9.2px 0px rgba(0, 0, 0, 0.15)'
                    >
                        <Text fontSize='small' color={props?.titleColor ? props?.titleColor : `${colors.getColor('white')}A6`}>
                            {title}
                        </Text>

                        <Wrapper position='absolute' right='5px' onClick={() => close && close()}>
                            <Icon fill={`${colors.getColor('white')}A6`} name='close' size={18} />
                        </Wrapper>
                    </Wrapper>
                )}

                {children}
            </Wrapper>
        </Wrapper>
    );
};

export default withStyles(Modal);
