import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({
    triggerRef,
    isOpen,
    children,
    portalStyle = {},
    containerPadding = 10,
    customTop = 0,
    customPosition = 'bottom',
    defaultWidth,
}) => {
    const portalRef = useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [isPositionReady, setIsPositionReady] = useState(false);

    useEffect(() => {
        if (isOpen && triggerRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const portalWidth = portalRef.current?.offsetWidth || defaultWidth;

            let leftPosition = triggerRect.left + window.scrollX;
            let topPosition = triggerRect.bottom + window.scrollY;

            if (customPosition === 'right') {
                leftPosition = triggerRect.right + window.scrollX;
                topPosition = triggerRect.top + window.scrollY;
            } else if (customPosition === 'left') {
                leftPosition = triggerRect.left - portalWidth + window.scrollX;
                topPosition = triggerRect.top + window.scrollY;
            }

            setPosition({
                top: topPosition + customTop,
                left: leftPosition,
            });

            setIsPositionReady(true);
        } else {
            setIsPositionReady(false);
        }
    }, [isOpen, triggerRef, containerPadding, customPosition, customTop, defaultWidth]);

    if (!isOpen || !isPositionReady) return null;

    return ReactDOM.createPortal(
        <div
            ref={portalRef}
            style={{
                position: 'absolute',
                top: `${position.top}px`,
                left: `${position.left}px`,
                zIndex: 99999999,
                ...portalStyle,
            }}
        >
            {children}
        </div>,
        document.body
    );
};

export default Portal;
