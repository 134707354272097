import React, { createContext, useState } from 'react';
import { SocketProvider } from './SocketContext';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [globalContext, setGlobalContext] = useState({ sitetreeVerification: true });

    return (
        <AppContext.Provider value={{ globalContext, setGlobalContext }}>
            <SocketProvider BASE_URL={process.env.BASE_URL}>{children}</SocketProvider>
        </AppContext.Provider>
    );
};
