import React, { useLayoutEffect, useEffect, useContext, useState, Suspense } from 'react';
import { AppContext } from '../Context';
import { Icon, Text, Tooltip, Wrapper } from '../components';
import Cookies from 'js-cookie';
import { BASE_URL, MISC_IMAGES } from '../Enviroment';
import { useLocation, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Navbar, LearMoreModal } from './Components';
import { getFallback } from '../assets/services/apis/api';

const Svg = styled.svg`
    fill: none;
    stroke: #555;
    stroke-width: 0.5;
    width: 80px;
    filter: drop-shadow(0 0 5px #1dc077);
`;

const Path = styled.path`
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: draw 1.3s ease infinite alternate, neon-glow 1s infinite alternate;
    stroke-linejoin: round;
    stroke-linecap: round;

    @keyframes draw {
        0% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes neon-glow {
        0% {
            filter: drop-shadow(0 0 5px rgba(29, 192, 119, 0.5));
        }

        50% {
            filter: drop-shadow(0 0 5px rgba(29, 192, 119, 0.5));
        }

        100% {
            filter: drop-shadow(0 0 5px rgba(29, 192, 119, 0.5));
        }
    }
`;

const BaseTemplate = () => {
    const { globalContext, setGlobalContext } = useContext(AppContext);

    const [selectedPageInfo, setSelectedPageInfo] = useState(getPageInfo());

    const [fakeLoading, setFakeLoading] = useState(false);
    const [isCompletedFirstLoad, setIsCompletedFirstLoad] = useState(false);

    const [isOpenLearnMoreModal, setIsOpenLearnMoreModal] = useState(false);
    const [isHoveredLearnMoreButton, setIsHoverLearnMoreButton] = useState(false);
    const [isAddedVersionControlListener, setIsAddedVersionControlListener] = useState(false);

    const location = useLocation();

    async function customFetch(endpoint, options = {}) {
        const urlComplete = `${BASE_URL}/${endpoint}`;
        const response = await fetch(urlComplete, options);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    }

    const getUserInfo = async () => {
        try {
            const sessionid = Cookies.get('sessionid');
            // const sessionid = 'iktyprlzs8ujgpwfvzj2hwtn6ik4xjt7';

            const data = {
                token: sessionid,
            };

            const requestInfo = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            };

            const response = await customFetch('auth/session', requestInfo);
            const user = await response?.json();

            setGlobalContext((prevContext) => {
                return { ...prevContext, user };
            });

            return user;
        } catch (error) {
            const url = new URL(`https://intra.avel.me/login/?next=/new-hub)`);
            const queryParams = {
                location: `${window.location.origin}/home`,
            };
            url.search = new URLSearchParams(queryParams).toString();
            window.location.href = url.toString();
        }
    };

    async function getAvailableAccessList(user) {
        const queryParams = { id: user?.id };

        const url = new URL(`${BASE_URL}/auth/available/access`);
        url.search = new URLSearchParams(queryParams).toString();

        const response = await fetch(url.toString());
        const data = response.ok ? await response.json() : [];

        setGlobalContext((prevContext) => {
            return { ...prevContext, accessList: data };
        });
    }

    async function getUserSitetree(user) {
        const queryParams = { id: user?.id };

        const url = new URL(`${BASE_URL}/auth/sitetree`);
        url.search = new URLSearchParams(queryParams).toString();

        const response = await fetch(url.toString());
        const data = response.ok ? await response.json() : {};

        setGlobalContext((prevContext) => {
            return { ...prevContext, sitetree: data };
        });
    }

    async function getSystemRest(user) {
        const queryParams = { id: user?.id };

        const url = new URL(`${BASE_URL}/system/rest`);
        url.search = new URLSearchParams(queryParams).toString();

        const response = await getFallback(url, false, 'only-warning');
        const data = response.data;

        setGlobalContext((prevContext) => {
            return { ...prevContext, ...data };
        });
    }

    async function getReportsControl(user) {
        const queryParams = { id: user?.id };

        const url = new URL(`${BASE_URL}/auth/reports/control`);
        url.search = new URLSearchParams(queryParams);

        const response = await getFallback(url, false, 'only-warning');
        const data = response.data;

        setGlobalContext((prevContext) => {
            return { ...prevContext, reportsControl: data };
        });
    }

    async function getSystemReleaseVersion(repo) {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'token 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',
        };

        const url = `https://api.github.com/repos/AvelTech/${repo}/releases/latest`;

        const response = await getFallback(url, false, false, new AbortController(), headers);
        const lastRelease = response.ok ? await response.json() : { tag_name: false };

        const contextKey = repo === 'hub' ? 'versionHub' : 'versionServer';

        setGlobalContext((prevContext) => {
            return { ...prevContext, [contextKey]: lastRelease.tag_name };
        });
    }

    function getPageInfo() {
        if (globalContext?.sitetree && globalContext?.selectedPageId !== null) {
            const currentPageId = globalContext?.selectedPageId;

            const allPages = [
                ...Object.values(globalContext?.sitetree?.tree)?.flatMap((item) => item.options),
                ...globalContext?.sitetree?.unnestedLinks,
            ];
            const pageInfo = allPages?.find((page) => page.id === currentPageId);

            document.title = `Intra | ${pageInfo?.display ?? 'Home'}`;
            return pageInfo;
        }
    }

    const handleVisibilityChange = async () => {
        const { versionHub, versionServer } = globalContext;

        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'token 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',
        };

        try {
            const fetchArray = ['hub', 'server_side'].map((gitKey) => {
                return getFallback(`https://api.github.com/repos/AvelTech/${gitKey}/releases/latest`, false, false, new AbortController(), headers);
            });

            await Promise.all(fetchArray).then(async ([gitHubResponse, gitServerResponse]) => {
                const [hubJson, serverJson] = await Promise.all([await gitHubResponse.json(), await gitServerResponse.json()]);

                if (hubJson.tag_name !== versionHub || serverJson.tag_name !== versionServer) {
                    window.location.reload();
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    function getBannerDescription(banner) {
        const spotlightKey = '<!#spotlight#!>';
        const spotlightValues = banner.spotlight_text;
        let spotlightCounter = 0;

        const splittedDescription = banner?.description?.split(' ');

        return (
            <Wrapper flexbox gap='0.2rem' style={{ flexWrap: 'wrap' }} center fontSize='tiny'>
                {splittedDescription?.map((word, index) => {
                    const isSpotlight = word === spotlightKey;
                    spotlightCounter += isSpotlight ? 1 : 0;

                    return (
                        <Text key={index} regular={!isSpotlight} strong={isSpotlight} color={banner.color} style={{ filter: 'brightness(1.2)' }}>
                            {isSpotlight ? spotlightValues[spotlightCounter - 1] : word}
                        </Text>
                    );
                })}
            </Wrapper>
        );
    }

    useEffect(() => {
        const fetchData = async () => {
            setFakeLoading(true);
            const requestUserInfo = await getUserInfo();

            window.hj('identify', requestUserInfo?.id, requestUserInfo);

            await Promise.all([
                getAvailableAccessList(requestUserInfo),
                getUserSitetree(requestUserInfo),
                getReportsControl(requestUserInfo),
                getSystemRest(requestUserInfo),
                getSystemReleaseVersion('hub'),
                getSystemReleaseVersion('server_side'),
            ]);
            setIsCompletedFirstLoad(true);
        };

        setFakeLoading(false);
        fetchData();
    }, []);

    useEffect(() => {
        setSelectedPageInfo(getPageInfo());
    }, [globalContext]);

    useLayoutEffect(() => {
        setFakeLoading(true);
        setTimeout(() => {
            isCompletedFirstLoad && setFakeLoading(false);
        }, 1000);
    }, [location.pathname, isCompletedFirstLoad]);

    useEffect(() => {
        if (globalContext.versionHub && globalContext.versionServer && !isAddedVersionControlListener) {
            setIsAddedVersionControlListener(true);

            setTimeout(() => {
                window.addEventListener('focus', handleVisibilityChange);
            }, 5000);
        }

        return () => {
            document.removeEventListener('focus', handleVisibilityChange);
        };
    }, [globalContext]);

    return (
        <Wrapper flexbox column position='relative' bgColor='background.00' height='100%' gap='big'>
            {fakeLoading && (
                <Wrapper position='absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} zIndex='9'>
                    <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96' role='img' aria-hidden='true' focusable='false'>
                        <Path
                            stroke='#1DC077'
                            strokeOpacity='1'
                            strokeWidth='1'
                            d='M67.8063 68.5027H67.8066C69.4816 68.5011 71.1407 68.2046 72.7 67.6292V78.8581C68.4794 81.5043 63.4933 82.8439 58.4306 82.6878L58.4306 82.6876H58.4213C50.5881 82.6876 44.5192 78.9139 42.4015 72.0882L42.1878 71.3994L41.8499 72.0365C38.1629 78.9893 30.9271 82.6876 20.7855 82.6876C14.5491 82.6876 9.42479 81.1587 5.86658 78.3726C2.3168 75.5931 0.3 71.5434 0.3 66.433C0.3 60.6383 2.49533 56.5183 6.4059 53.5415C10.3348 50.5509 16.0141 48.7004 22.9992 47.5148C22.9997 47.5147 23.0002 47.5146 23.0007 47.5145L41.0317 44.643L41.2845 44.6027V44.3467V43.3354C41.2845 40.7632 40.6608 38.6541 39.2734 37.188C37.8832 35.7188 35.7738 34.9452 32.9113 34.9452C30.2439 34.9452 28.2173 35.7535 26.7287 37.1651C25.3 38.5198 24.3918 40.4063 23.8673 42.596L2.76811 41.3265C3.81678 34.833 6.90184 29.3461 12.0519 25.4604C17.291 21.5074 24.6927 19.1928 34.3191 19.1928C42.817 19.1928 50.1406 20.9315 55.3323 24.7447C60.5091 28.547 63.603 34.4387 63.603 42.8399V63.6823C63.603 65.2955 63.9745 66.5106 64.7127 67.3236C65.4558 68.1419 66.5243 68.5027 67.8063 68.5027ZM41.2525 55.1977V54.8417L40.9017 54.9021L31.3781 56.5403L31.3781 56.5402L31.3713 56.5416C28.5495 57.0939 26.2611 57.7879 24.6746 58.8685C23.0648 59.9649 22.1812 61.4582 22.1812 63.5509C22.1812 65.2141 22.8729 66.6002 24.0819 67.5638C25.2847 68.5225 26.9798 69.0488 28.976 69.0488C32.3689 69.0488 35.4419 67.8602 37.6676 65.6431C39.8945 63.4248 41.2525 60.1973 41.2525 56.1584V55.1977ZM8.60057 10.4726L8.56012 0.484591L58.9584 0.30109L58.9989 10.2891L8.60057 10.4726Z'
                        />
                    </Svg>
                </Wrapper>
            )}

            {!globalContext?.isTemplateHidden && (
                <Wrapper height='8.175rem' style={{ position: 'sticky' }} zIndex='100' top='0'>
                    <Navbar globalContext={globalContext} />
                </Wrapper>
            )}

            <Wrapper
                id='template-container'
                flex
                flexbox
                column
                padding='0 4.5rem'
                height='calc(100% - 8.175rem)'
                justify='space-between'
                gap
                style={{ filter: fakeLoading && 'blur(1.5rem)', transition: 'filter 0.05s linear', overflowY: 'scroll' }}
            >
                <Wrapper flexbox column gap='big'>
                    {!globalContext?.isTemplateHidden && globalContext?.sitetree && globalContext?.selectedPageId !== 0 && (
                        <Wrapper flexbox gap align='center'>
                            <Text color='white' opacity='50%' light>
                                {selectedPageInfo?.parentName ? `${selectedPageInfo?.parentName} /` : ''}
                            </Text>
                            <Text color='white'>{selectedPageInfo?.display}</Text>

                            {selectedPageInfo?.learnMoreInfo.length > 0 && (
                                <Tooltip type='simple' isArrowType description='Aprender mais sobre a página'>
                                    <Wrapper
                                        width='fit-content'
                                        onClick={() => setIsOpenLearnMoreModal(true)}
                                        onMouseEnter={() => setIsHoverLearnMoreButton(true)}
                                        onMouseLeave={() => setIsHoverLearnMoreButton(false)}
                                    >
                                        <img
                                            src={`${MISC_IMAGES}/avel_academy.png`}
                                            style={{
                                                height: '2rem',
                                                width: 'auto',
                                                filter: isHoveredLearnMoreButton ? 'grayscale(0)' : 'grayscale(1)',
                                                transition: 'all 0.3s ease-in-out',
                                            }}
                                        />
                                    </Wrapper>
                                </Tooltip>
                            )}
                        </Wrapper>
                    )}

                    {!globalContext?.isTemplateHidden && globalContext?.banners?.[globalContext?.selectedPageId]?.length > 0 && (
                        <Wrapper flexbox column gap>
                            {globalContext.banners[globalContext?.selectedPageId].map((banner, index) => {
                                return (
                                    <Wrapper
                                        key={`${banner.id}_${index}`}
                                        flexbox
                                        gap
                                        padding='0.5rem'
                                        border={`1px solid ${banner.color}33`}
                                        corner='bowed'
                                        align='center'
                                        bgImage={`linear-gradient(to right, ${banner.color}1A, #232323)`}
                                    >
                                        <Wrapper flexbox center style={{ filter: 'brightness(1.2)' }}>
                                            <Icon name={banner.icon} fill={banner.color} size={24} />
                                        </Wrapper>

                                        <Wrapper flexbox column gap='small'>
                                            <Text fontSize='tiny' strong color={banner.color} style={{ filter: 'brightness(1.2)' }}>
                                                {banner.title}
                                            </Text>

                                            {getBannerDescription(banner)}
                                        </Wrapper>
                                    </Wrapper>
                                );
                            })}
                        </Wrapper>
                    )}

                    {isCompletedFirstLoad && (
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    )}
                </Wrapper>

                {!globalContext?.isTemplateHidden && (
                    <Wrapper flexbox width='100%' center padding>
                        <img style={{ height: '21px', width: '224px' }} src={`${MISC_IMAGES}/footer_aveltech.svg`} alt='avelLogo'></img>
                    </Wrapper>
                )}
            </Wrapper>

            <LearMoreModal
                isOpen={isOpenLearnMoreModal}
                closeModal={() => setIsOpenLearnMoreModal(false)}
                pageInfo={selectedPageInfo?.learnMoreInfo}
            />
        </Wrapper>
    );
};

export default BaseTemplate;
