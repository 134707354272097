import React, { useState, useRef } from 'react';
import { Wrapper, Text, Icon } from '../../../components';
import Portal from '../../Portal';
import useOutsideClick from '../../../Hooks/useOutsideClick';

const Option = ({ option, width, onClickOption, level = 0, ...props }) => {
    const haveNestedOptions = option?.options?.length > 0;

    const [isOpenNestedOptions, setIsOpenNestedOptions] = useState(false);
    const optionRef = useRef(null);
    const portalRef = useRef(null);

    const handleHoverOption = (bool) => {
        setIsOpenNestedOptions(bool);
    };

    const renderNestedOptions = (nestedOptions, nextLevel) => {
        return nestedOptions?.map((opt, idx) => <Option key={idx} option={opt} width={width} level={nextLevel} onClickOption={onClickOption} />);
    };

    const handleClick = (event) => {
        event.stopPropagation();
        if (!haveNestedOptions && !option.disabled) {
            onClickOption(option);
        }
    };

    useOutsideClick([optionRef, portalRef], () => {
        setIsOpenNestedOptions(false);
    });

    return (
        <Wrapper
            ref={optionRef}
            {...props}
            width={width}
            position='relative'
            flexbox
            padding='horizontal'
            opacity={option.disabled && '0.5'}
            border='bottom'
            bgColor='background.05'
            bgHover='background.08'
            onClick={handleClick}
            minHeight='2.5rem'
            onMouseEnter={() => haveNestedOptions && handleHoverOption(true)}
            onMouseLeave={() => haveNestedOptions && handleHoverOption(false)}
        >
            <Wrapper flexbox justify='space-between' width='100%' align='center'>
                <Text id={option?.key} truncate>
                    {option.name}
                </Text>

                {haveNestedOptions && (
                    <Wrapper flexbox>
                        <Icon name='chevron_right' size={25} fill='white' />
                    </Wrapper>
                )}
            </Wrapper>

            {haveNestedOptions && isOpenNestedOptions && (
                <Portal
                    triggerRef={optionRef}
                    isOpen={isOpenNestedOptions}
                    onClose={() => setIsOpenNestedOptions(false)}
                    customPosition='right'
                    defaultWidth={width}
                >
                    <div ref={portalRef} onClick={(e) => e.stopPropagation()}>
                        <Wrapper flexbox column maxHeight='15rem' fontSize='small' bgColor='background.06' style={{ overflowY: 'scroll' }}>
                            {renderNestedOptions(option.options, level + 1)}
                        </Wrapper>
                    </div>
                </Portal>
            )}
        </Wrapper>
    );
};

Option.defaultProps = {
    selected: false,
};

export default Option;
