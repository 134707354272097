import React, { createContext, useContext, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { BASE_URL } from '../../Enviroment';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const socketRef = useRef(null);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(BASE_URL, {
                path: '/socket.io',
                transports: ['websocket'],
                reconnectionAttempts: Infinity,
                perMessageDeflate: false,
            });

            socketRef.current.on('connect', () => {
                console.log('Socket conectado:', socketRef.current.id);
            });

            socketRef.current.on('disconnect', (reason) => {
                console.warn(`Conexão desconectada: ${reason}`);
            });

            socketRef.current.on('connect_error', (err) => {
                console.error('Erro ao conectar ao servidor Socket.IO:', err.message);
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    return <SocketContext.Provider value={socketRef.current}>{children}</SocketContext.Provider>;
};
