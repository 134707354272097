import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper, Text, Icon, Box, Tooltip } from '../../../components';
import { TEAM_IMAGES, LEGACY_URL, DOODLE_IMAGES, MISC_IMAGES } from '../../../Enviroment';
import { ProfileInfo, Submenu } from '../../../App/components';
import { BASE_URL } from '../../../Enviroment';
import { DownloadPdf, removeElementsOpacity } from '../../../assets/services/apis/utils';
import { useOutsideClick } from '../../../Hooks';
import useImageCheck from '../../../Hooks/useImageCheck';
import styled, { keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import { momentOrDefault } from '../../../assets/services/formatters/dates';
import CreateGeneralGoogleMeetingModal from '../../../App/components/CreateGeneralGoogleMeetingModal';

const pulseAnimation = keyframes`
    0% {
        box-shadow: 0 0 0 0px rgba(45, 153, 186, 0.5);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
`;

const PulseWrapper = styled(Wrapper)`
    z-index: 1;
    animation: ${pulseAnimation} 1.2s infinite;
`;

const Navbar = ({ globalContext }) => {
    const requestUserInfo = globalContext.user;
    const userSitetree = globalContext.sitetree;
    const reportsControl = globalContext.reportsControl;
    const profilePhotoKey = globalContext.profilePhotoKey;

    const meetDropdownRef = useRef(null);
    const reportsControlDropdownRef = useRef(null);
    const ticketsDropdownRef = useRef(null);
    const openProfileDropDownRef = useRef(null);

    const navigate = useNavigate();

    const [sectorSelected, setSectorSelected] = useState(requestUserInfo?.sectorId);
    const [companySectors, setCompanySectors] = useState([]);

    const [isOpenMeeting, setIsOpenMeeting] = useState(false);

    const [teamImage, setTeamImage] = useState(null);
    useImageCheck(teamImage, () => setTeamImage(`${TEAM_IMAGES}/avel.png`));

    useOutsideClick([meetDropdownRef, openProfileDropDownRef, ticketsDropdownRef, reportsControlDropdownRef], () =>
        setDropdownStates({
            isOpenProfile: false,
            isOpenTicketsOptions: false,
            isOpenReportsControl: false,
        })
    );

    const [dowpdownStates, setDropdownStates] = useState({
        isOpenProfile: false,
        isOpenTicketsOptions: false,
        isOpenReportsControl: false,
    });

    const unNestedLinks = userSitetree?.unnestedLinks;
    const linkLabels = userSitetree?.tree;

    async function getCompanyStructure() {
        const url = `${BASE_URL}/auth/company/roles`;

        const response = await fetch(url);
        const data = response.ok ? await response.json() : [];

        setCompanySectors(data);
    }

    const getTeamIconUrl = () => {
        const iconSuffix = [10, 33, 34].includes(sectorSelected) ? requestUserInfo.bigTeamId : `sector_team_${sectorSelected}`;
        return `${TEAM_IMAGES}/${iconSuffix}.png`;
    };

    const openDropdown = (key) => {
        setDropdownStates((prevState) => ({
            ...prevState,
            isOpenReportsControl: false,
            isOpenTicketsOptions: false,
            isOpenProfile: false,
            [key]: !prevState[key],
        }));
    };

    const getReportInfoTooltip = (report) => {
        return (
            <Wrapper flexbox column>
                <Text>{`Atualização: ${momentOrDefault(report.updatedTime, 'DD/MM/YY - hh:mm', '')}`}</Text>
                <Text>{`Último registro: ${momentOrDefault(report.lastReportRecord, 'DD/MM/YY', '')}`}</Text>
            </Wrapper>
        );
    };

    const onLogout = () => {
        Cookies.remove('sessionid');

        window.location.href = 'https://intra.avel.me/login/';
    };

    useEffect(() => {
        getCompanyStructure();
    }, []);

    useEffect(() => {
        if (requestUserInfo && sectorSelected) {
            setTeamImage(getTeamIconUrl());
        }
    }, [sectorSelected, requestUserInfo]);

    useEffect(() => {
        removeElementsOpacity();
    }, [userSitetree]);

    useEffect(() => {
        setSectorSelected(requestUserInfo?.sectorId);
    }, [requestUserInfo]);

    return (
        <Wrapper flexbox column height='100%' width='100%' bgColor='background.03'>
            <Wrapper flex flexbox color='white' height='53%' padding='0 4.5rem' align='center' justify='space-between'>
                <Wrapper flexbox gap align='center'>
                    <img
                        alt='avelLogo'
                        style={{ height: '43px', width: 'auto', marginBottom: '6px', paddingRight: '5px', cursor: 'pointer' }}
                        src={`${DOODLE_IMAGES}/current.png`}
                        onClick={() => navigate('home')}
                    />

                    <img
                        alt='teamLogo'
                        src={teamImage}
                        style={{
                            height: sectorSelected === 10 ? '50px' : '35px',
                            width: 'auto',
                            borderLeft: '1px solid #535353',
                            paddingLeft: '10px',
                        }}
                    />
                </Wrapper>

                <Wrapper className='no_highlight' flexbox align='center' height='100%' width='50%' justify='end'>
                    {(requestUserInfo?.sectorId === 1 || requestUserInfo?.sectorId === 33) && (
                        <Wrapper ref={meetDropdownRef} flexbox height='100%' position='relative' bgColor={isOpenMeeting && 'background.07'}>
                            <Wrapper flexbox center height='100%' padding='0 1rem' onClick={() => setIsOpenMeeting(true)}>
                                <Icon
                                    name='google_meet_bw'
                                    size={25}
                                    fill='none'
                                    style={{
                                        stroke: 'white',
                                        strokeWidth: '2',
                                    }}
                                />
                            </Wrapper>
                        </Wrapper>
                    )}

                    <Wrapper
                        ref={reportsControlDropdownRef}
                        flexbox
                        height='100%'
                        position='relative'
                        bgColor={dowpdownStates.isOpenReportsControl && 'background.07'}
                    >
                        <Wrapper flexbox center height='100%' padding='0 1rem' onClick={() => openDropdown('isOpenReportsControl')}>
                            <Icon name='overview' size={28} fill='white' />
                        </Wrapper>

                        {dowpdownStates.isOpenReportsControl && (
                            <Box
                                flexbox
                                column
                                position='absolute'
                                right='0'
                                bgColor='background.06'
                                zIndex='9999'
                                width='20rem'
                                top='102%'
                                gap
                                corner='bowed'
                            >
                                <Wrapper padding='1.5rem' border='bottom'>
                                    <Text strong>Atualização Relatórios</Text>
                                </Wrapper>

                                <Wrapper flexbox column padding='0.5rem 0' maxHeight='20rem' style={{ overflowY: 'scroll' }}>
                                    {reportsControl.map((report) => {
                                        return (
                                            <Wrapper key={report.tableId} flexbox align='center' justify='space-between' padding='1rem 1.5rem'>
                                                <Text fontSize='small' color='gray'>
                                                    {report.name}
                                                </Text>

                                                <Wrapper flexbox center gap>
                                                    <Text fontSize='tiny' strong>
                                                        {momentOrDefault(report.lastReportRecord, 'DD/MM/YY', '-')}
                                                    </Text>

                                                    <Tooltip isArrowType description={getReportInfoTooltip(report)}>
                                                        <Wrapper flexbox center>
                                                            <Icon name='info' size={15} fill='white' />
                                                        </Wrapper>
                                                    </Tooltip>
                                                </Wrapper>
                                            </Wrapper>
                                        );
                                    })}
                                </Wrapper>
                            </Box>
                        )}
                    </Wrapper>

                    <Wrapper
                        ref={ticketsDropdownRef}
                        flexbox
                        height='100%'
                        position='relative'
                        bgColor={dowpdownStates.isOpenTicketsOptions && 'background.07'}
                    >
                        <Wrapper
                            position='relative'
                            flexbox
                            center
                            height='100%'
                            padding='0 1rem'
                            onClick={() => openDropdown('isOpenTicketsOptions')}
                        >
                            <Icon name='tickets' size={28} fill='white' />

                            <PulseWrapper
                                position='absolute'
                                bgColor='#2D99BA'
                                padding='0 0.3rem'
                                corner='default'
                                top='15%'
                                right='-20%'
                                justify='space-evenly'
                            >
                                <Text fontSize='mini'>Tickets</Text>
                            </PulseWrapper>
                        </Wrapper>

                        {dowpdownStates.isOpenTicketsOptions && (
                            <Box
                                flexbox
                                column
                                position='absolute'
                                right='0'
                                bgColor='background.06'
                                zIndex='9999'
                                width='200px'
                                top='102%'
                                gap
                                corner='bowed'
                            >
                                <Wrapper flexbox column>
                                    <Text border='bottom' strong padding='24px'>
                                        Tickets
                                    </Text>

                                    <Wrapper flexbox column padding='8px 0'>
                                        <Wrapper
                                            padding='16px 24px'
                                            opacity='0.5'
                                            opacityHover='1'
                                            onClick={() => {
                                                navigate('hub/tickets');
                                                openDropdown('isOpenTicketsOptions');
                                            }}
                                        >
                                            <Text fontSize='small'>Meus Tickets</Text>
                                        </Wrapper>
                                    </Wrapper>
                                </Wrapper>
                            </Box>
                        )}
                    </Wrapper>

                    <Wrapper
                        ref={openProfileDropDownRef}
                        flexbox
                        height='100%'
                        position='relative'
                        bgColor={dowpdownStates.isOpenProfile && 'background.07'}
                    >
                        <Wrapper flexbox height='100%' gap='big' center padding='0 1rem' onClick={() => openDropdown('isOpenProfile')}>
                            <ProfileInfo
                                key={`profile_photo_${profilePhotoKey}`}
                                imageProps={{ width: '35px', height: '35px' }}
                                src={`${requestUserInfo?.id}/profile.jpg`}
                                textProps={{ fontSize: 'tiny', opacity: '0.9' }}
                                name={requestUserInfo?.name}
                            >
                                <Text fontSize='tiny' opacity='0.6' regular>
                                    {requestUserInfo?.sector}
                                </Text>
                            </ProfileInfo>
                        </Wrapper>

                        {dowpdownStates.isOpenProfile && (
                            <Box
                                flexbox
                                column
                                position='absolute'
                                right='0'
                                bgColor='background.06'
                                zIndex='9999'
                                width='300px'
                                top='102%'
                                boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
                                corner='bowed'
                            >
                                <Wrapper flex column padding='24px' border='bottom'>
                                    <Text strong>{requestUserInfo?.name}</Text>
                                    <Text fontSize='small' opacity='0.4'>
                                        {requestUserInfo?.email}
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    bgHover='background.08'
                                    margin='4px 3px 2px 3px'
                                    corner='default'
                                    flexbox
                                    padding='0.65rem 0.75rem'
                                    gap
                                    align='center'
                                    onClick={() => navigate('settings')}
                                >
                                    <Icon name='settings' size='16' fill='white' />
                                    <Text fontSize='small'>Configurações</Text>
                                </Wrapper>

                                <Wrapper
                                    bgHover='background.08'
                                    margin='2px 3px 4px 3px'
                                    corner='default'
                                    flexbox
                                    padding='0.65rem 0.75rem'
                                    gap
                                    align='center'
                                    onClick={onLogout}
                                >
                                    <Icon name='logout' size='16' fill='danger' />
                                    <Text fontSize='small' color='danger'>
                                        Sair
                                    </Text>
                                </Wrapper>
                            </Box>
                        )}
                    </Wrapper>
                </Wrapper>
            </Wrapper>

            <Wrapper flex flexbox height='47%' bgColor='background.02' padding='0 4.5rem' justify='space-between'>
                <Wrapper flexbox gap='2rem'>
                    <Wrapper
                        flexbox
                        align='center'
                        gap='small'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('home')}
                        opacity={globalContext?.selectedPageId === 0 ? '1' : '0.5'}
                    >
                        <Icon name='home' fill='white' size={20}></Icon>
                        <Text color='white' fontSize='small' regular>
                            Home
                        </Text>
                    </Wrapper>

                    {userSitetree && (
                        <Wrapper flexbox gap='2rem' className='opacity_card'>
                            {unNestedLinks?.map((link, index) => {
                                const linkPath = link.path.includes('http')
                                    ? link.path
                                    : link.isLegacy
                                    ? `${LEGACY_URL}/${link.path}`
                                    : `${link.parent}/${link.path}`;

                                return (
                                    <Wrapper
                                        key={index}
                                        flexbox
                                        align='center'
                                        gap='small'
                                        style={{ cursor: 'pointer' }}
                                        opacity={globalContext?.selectedPageId === link?.id ? '1' : '0.5'}
                                        opacityHover='1'
                                        onClick={() => {
                                            link.isLegacy || link.path.includes('http') ? window.open(linkPath).focus() : navigate(linkPath);
                                        }}
                                    >
                                        <Icon name={link.icon} size={20} fill='white'></Icon>
                                        <Text color='white' fontSize='small' regular>
                                            {link.display}{' '}
                                        </Text>
                                    </Wrapper>
                                );
                            })}

                            {Object.keys(linkLabels)?.map((key, index) => {
                                const labelItem = linkLabels[key];

                                return (
                                    <Wrapper flexbox align='center' key={index}>
                                        <Submenu
                                            selectedPageId={globalContext?.selectedPageId}
                                            labelOut={labelItem.name}
                                            labelIcon={labelItem.icon}
                                            options={labelItem.options.filter((item) => !item.isHidden)}
                                            onClick={(path, isLegacy) => {
                                                isLegacy ? window.open(path, '_blank').focus() : navigate(`${labelItem.path}/${path}`);
                                            }}
                                        />
                                    </Wrapper>
                                );
                            })}
                        </Wrapper>
                    )}
                </Wrapper>

                <Wrapper flexbox align='center' gap>
                    <Submenu
                        labelOut='Links'
                        labelIcon='captive_portal'
                        xAxisDirection='right'
                        onClick={(path) => {
                            if (path === 'file_pdf') {
                                DownloadPdf('/pdfs/links/file_outubro.pdf', 'file_mensal');
                            } else {
                                window.open(path, '_blank').focus();
                            }
                        }}
                        options={[
                            {
                                path: 'https://us06web.zoom.us/j/3596741874?pwd=dACRvbPYPnV8EFSwEUMemujeCTQl8g.1#success',
                                display: () => (
                                    <Wrapper gap flexbox align='center'>
                                        <img src={`${MISC_IMAGES}/zoom_icon.svg`} style={{ height: '1.8rem', width: 'auto' }} />
                                        <Text>Matinal Zoom</Text>
                                    </Wrapper>
                                ),
                            },
                            {
                                path: 'https://avel-store-.kyte.site/',
                                display: () => (
                                    <Wrapper gap flexbox align='center'>
                                        <img src={`${MISC_IMAGES}/avelstore_icon.svg`} style={{ height: '1.8rem', width: 'auto' }} />
                                        <Text>Ável Store</Text>
                                    </Wrapper>
                                ),
                            },
                            {
                                path: 'https://wa.me/555131810456/',
                                display: () => (
                                    <Wrapper gap flexbox align='center'>
                                        <img src={`${MISC_IMAGES}/suporte_ti.svg`} style={{ height: '1.8rem', width: 'auto' }} />
                                        <Text>Suporte TI</Text>
                                    </Wrapper>
                                ),
                            },
                        ]}
                    />
                </Wrapper>
            </Wrapper>

            {isOpenMeeting && (
                <CreateGeneralGoogleMeetingModal isOpen={isOpenMeeting} closeModal={() => setIsOpenMeeting(false)} selectedUser={requestUserInfo} />
            )}
        </Wrapper>
    );
};

export default Navbar;
