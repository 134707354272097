import React, { useEffect, useState } from 'react';
import { Wrapper, Modal, Input, Text, Select, Button } from '../../../../../../../components';
import { AWS_API_TOKEN, BASE_URL, LAMBDA_URL } from '../../../../../../../Enviroment';
import { getFallback, postFallback, putFallback } from '../../../../../../../assets/services/apis/api';
import moment from 'moment';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    to {
      transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 40px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        border-radius: inherit;
    }

    &:before {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, #1dc077 0%, #1e1e1e 100%);
        animation: ${rotate} 0.5s infinite linear;
    }

    &:after {
        width: 85%;
        height: 85%;
        background-color: #353535;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const CreateActivityModal = ({ requestUser, selectedUser, selectedCustomer, closeModal, handleMaskUpdates, sectorsColumns, onUpdateRow }) => {
    const [activitiesOptions, setActivitiesOptions] = useState(null);

    const defaultFormController = { title: '', date: moment().format('YYYY-MM-DD'), description: '' };
    const [formController, setFormController] = useState(defaultFormController);

    async function createActivity() {
        const requiredFields = getActivityDetailFields(formController.detailId)?.filter((field) => field?.isRequired);

        const isCheckRequiredFields = requiredFields.every((field) => formController?.details?.[field?.key]);
        const isDefaultCheckRequiredFields = ['date', 'description'].every((field) => formController?.[field]);

        if (!isCheckRequiredFields || !isDefaultCheckRequiredFields) {
            return alert('Preencha todos os campos necessários');
        }

        const queryParams = { id: requestUser.id };

        const body = {
            creatorId: requestUser.id,
            authorId: selectedUser.id,
            dealId: selectedCustomer.dealId,
            customerCode: selectedCustomer.customerCode,
            ...formController,
        };

        const url = new URL(`${BASE_URL}/commercial/customers/portfolio/create/activity`);
        url.search = new URLSearchParams(queryParams);

        const productKey = sectorsColumns?.[selectedUser.sectorId]?.key?.slice(0, -5);
        const newCustomerValue = { ...selectedCustomer, [productKey]: { ...selectedCustomer[productKey], introduced: true } };

        onUpdateRow && onUpdateRow(newCustomerValue);
        handleMaskUpdates && handleMaskUpdates(newCustomerValue);

        await postFallback(url, JSON.stringify(body), true, true).then(async (res) => {
            if (res.ok || res.warning) {
                sendActivityToPipedrive(res.data.id, body);
                closeModal();
            } else {
                onUpdateRow && onUpdateRow(selectedCustomer);
                handleMaskUpdates && handleMaskUpdates(selectedCustomer);
            }
        });
    }

    async function sendActivityToPipedrive(activityId, body) {
        const formattedNote = `[${selectedUser.name} - ${selectedUser.sector}]: ${body.description}`;

        const note = formattedNote.replace(/\n/g, '\\n');

        const pipeBody = {
            activityType: 'mesa_is',
            dealId: body.dealId,
            token: AWS_API_TOKEN,
            dueDate: moment(body.date).format('YYYY-MM-DD'),
            dueTime: moment(body.date).format('hh:mm'),
            pipeId: 0,
            note,
        };

        await putFallback(`${LAMBDA_URL}/create-activity`, JSON.stringify(pipeBody), false, 'only-warning').then(async (res) => {
            if (res.ok) {
                const updateBody = {
                    activityId,
                    key: 'pipe_activity_id',
                    value: res?.data?.activityId,
                };

                putFallback(`${BASE_URL}/commercial/customers/portfolio/update/activity`, JSON.stringify(updateBody), false, false);
            }
        });
    }

    const handleFormController = (key, value, field = false) => {
        if (key === 'activityOption') {
            const newFormController = { ...formController, detailId: value.id };

            if (newFormController.details) {
                delete newFormController['details'];
            }

            setFormController(newFormController);
            return;
        } else if (key === 'details') {
            if (field.type.type === 'select') {
                setFormController((prevState) => {
                    return { ...prevState, details: { ...prevState.details, [field.id]: value } };
                });
            } else {
                const inputTarget = value.target;
                const inputValue = field.type.type === 'number' ? Number(inputTarget.value) : inputTarget.value;
                setFormController((prevState) => {
                    return { ...prevState, details: { ...prevState.details, [field.id]: inputValue } };
                });
            }

            return;
        }

        setFormController((prevState) => {
            return { ...prevState, [key]: value };
        });
    };

    async function getActivityOptionsByUser() {
        const queryParams = { id: selectedUser.id };

        const url = new URL(`${BASE_URL}/commercial/customers/portfolio/activity/options`);
        url.search = new URLSearchParams(queryParams);

        const res = await getFallback(url, false, 'only-warning');

        setActivitiesOptions(res.data);
    }

    const getActivityDetailFields = (detaildId) => {
        return activitiesOptions.flatMap((activityType) => activityType.options).find((option) => option.id === detaildId)?.fields || [];
    };

    useEffect(() => {
        if (selectedCustomer && !activitiesOptions) getActivityOptionsByUser();
        setFormController(defaultFormController);
    }, [selectedCustomer]);

    return (
        <Modal open={selectedCustomer}>
            {activitiesOptions ? (
                <Wrapper flexbox column bgColor='background.05' width='30vw' padding gap='huge'>
                    <Text fontSize='large' strong>
                        Marcar Atividade
                    </Text>

                    <Wrapper flexbox column gap='large'>
                        <Input
                            label='Titulo'
                            value={formController.title}
                            placeholder='Dê um título para a atividade'
                            onChange={(e) => handleFormController('title', e.target.value)}
                            height='2.2rem'
                        />

                        <Wrapper flexbox gap width='100%'>
                            <Wrapper width='50%'>
                                <Select
                                    id='activityOption'
                                    label={{ display: 'Tipo' }}
                                    isRequired
                                    options={activitiesOptions}
                                    selectedValue={activitiesOptions
                                        .flatMap((option) => option.options)
                                        .find((option) => option.id === formController?.detailId)}
                                    onChangeOption={(id, value) => handleFormController('activityOption', value)}
                                    height='2.2rem'
                                />
                            </Wrapper>

                            <Wrapper width='50%'>
                                <Input
                                    label='Data'
                                    isRequired
                                    type='date'
                                    value={formController.date}
                                    onChange={(e) => handleFormController('date', e.target.value)}
                                    height='2.2rem'
                                />
                            </Wrapper>
                        </Wrapper>

                        {Array.from({ length: Math.ceil(getActivityDetailFields(formController.detailId)?.length / 2) }).map((_, index) => {
                            const firstItem = getActivityDetailFields(formController.detailId)[2 * index];
                            const secondItem = getActivityDetailFields(formController.detailId)[2 * index + 1];

                            return (
                                <Wrapper key={index} flexbox gap>
                                    <Wrapper width='50%'>
                                        {firstItem.type.type === 'select' ? (
                                            <Select
                                                id={firstItem?.id}
                                                withSearch
                                                isRequired={firstItem.isRequired}
                                                options={firstItem?.type?.options || []}
                                                label={{ display: firstItem?.name, isRequired: false }}
                                                onChangeOption={(id, value) => handleFormController('details', value, firstItem)}
                                                selectedValue={formController?.details?.[String(firstItem?.id)]}
                                            />
                                        ) : (
                                            <Input
                                                id={firstItem?.id}
                                                isRequired={firstItem.isRequired}
                                                label={firstItem?.name}
                                                type={firstItem?.type?.type}
                                                height={firstItem?.type?.type === 'textarea' ? '5rem' : '2.2rem'}
                                                onChange={(event) => handleFormController('details', event, firstItem)}
                                                value={formController[String(firstItem?.id)]}
                                            />
                                        )}
                                    </Wrapper>
                                    <Wrapper width='50%' height='100%'>
                                        {secondItem &&
                                            (secondItem?.type?.type === 'select' ? (
                                                <Select
                                                    id={secondItem?.id}
                                                    withSearch
                                                    isRequired={secondItem.isRequired}
                                                    options={secondItem?.type?.options || []}
                                                    label={{ display: secondItem?.name, isRequired: false }}
                                                    onChangeOption={(id, value) => handleFormController('details', value, secondItem)}
                                                    selectedValue={formController?.details?.[String(secondItem?.id)]}
                                                />
                                            ) : (
                                                <Input
                                                    id={secondItem?.id}
                                                    isRequired={secondItem.isRequired}
                                                    label={secondItem?.name}
                                                    height='2.2rem'
                                                    type={secondItem?.type?.type}
                                                    onChange={(event) => handleFormController('details', event, secondItem)}
                                                    value={formController[String(secondItem?.id)]}
                                                />
                                            ))}
                                    </Wrapper>
                                </Wrapper>
                            );
                        })}

                        <Input
                            label='Descrição'
                            isRequired
                            type='textarea'
                            placeholder='Descreva brevemente a atividade'
                            value={formController.description}
                            onChange={(e) => handleFormController('description', e.target.value)}
                            height='5rem'
                        />
                    </Wrapper>

                    <Wrapper flexbox justify='end'>
                        <Button kind='clean' onClick={closeModal}>
                            <Text>Cancelar</Text>
                        </Button>

                        <Button color='success' onClick={createActivity}>
                            <Text>Marcar Atividade</Text>
                        </Button>
                    </Wrapper>
                </Wrapper>
            ) : (
                <Wrapper flexbox center bgColor='background.05' width='30vw' height='50vh' padding>
                    <LoaderWrapper />
                </Wrapper>
            )}
        </Modal>
    );
};

export default CreateActivityModal;
