import React, { useState, useRef, useCallback, useEffect } from 'react';
import Wrapper from '../../../Wrapper';
import Input from '../../../Input';
import Text from '../../../Text';
import Icon from '../../../Icon';
import { useOutsideClick } from '../../../../Hooks';
import Portal from '../../../Portal';

const FilterWindow = ({ column, sortedData, matchesSearch, state, dispatch, setCurrentPaginationPage, searchInputValue }) => {
    const filterRef = useRef(null);
    const filterWindowRef = useRef(null);

    const [isOpenFilterWindow, setIsOpenFilterWindow] = useState(false);
    const [filterInputValue, setFilterInputValue] = useState('');

    useOutsideClick([filterRef, filterWindowRef], () => {
        if (isOpenFilterWindow) {
            setIsOpenFilterWindow(false);
        }
    });

    const getDistinctFilterValues = useCallback(
        (filterKey) => {
            const uniqueValues = [...new Set(sortedData.filter((item) => matchesSearch(item)).map((value) => value[filterKey]))];
            return uniqueValues.filter((value) => value !== undefined && String(value).toLowerCase().includes(filterInputValue.toLowerCase()));
        },
        [sortedData, filterInputValue]
    );

    const handleFilterOptions = (data, key) => {
        const currentFilters = state?.filterOptions?.filters || {};
        if (currentFilters[key]) {
            if (currentFilters?.[key]?.some((value) => value === data)) {
                currentFilters[key] = currentFilters[key].filter((value) => value !== data);
            } else {
                currentFilters[key].push(data);
            }
        } else {
            currentFilters[key] = [data];
        }

        const object = { ...state.filterOptions, filters: currentFilters };
        setCurrentPaginationPage(0);
        dispatch({ type: 'SET_FILTER', filterOptions: object });
    };

    const handleSelectAll = (key, allOptions) => {
        const currentFilters = state?.filterOptions?.filters || {};

        if (currentFilters[key]?.length === allOptions.length) {
            currentFilters[key] = [];
        } else {
            currentFilters[key] = [...allOptions];
        }

        const object = { ...state.filterOptions, filters: currentFilters };
        setCurrentPaginationPage(0);
        dispatch({ type: 'SET_FILTER', filterOptions: object });
    };

    const distinctValues = getDistinctFilterValues(column.key);
    const isAllSelected = state?.filterOptions?.filters?.[column.key]?.length === distinctValues.length;

    const handleScroll = (event) => {
        if (
            filterWindowRef.current &&
            !filterWindowRef.current.contains(event.target) &&
            filterRef.current &&
            !filterRef.current.contains(event.target)
        ) {
            setIsOpenFilterWindow(false);
        }
    };

    useEffect(() => {
        if (isOpenFilterWindow) {
            setFilterInputValue('');

            window.addEventListener('scroll', handleScroll, true);

            return () => {
                window.removeEventListener('scroll', handleScroll, true);
            };
        }
    }, [isOpenFilterWindow]);

    return (
        <Wrapper flexbox ref={filterRef}>
            <Wrapper
                flexbox
                bgColor={(isOpenFilterWindow || state?.filterOptions?.filters?.[column.key]?.length) && '#295B45'}
                bgHover='#295B45'
                transition='background 0.1s ease-in-out'
                corner='default'
                onClick={() => setIsOpenFilterWindow((prevState) => !prevState)}
            >
                <Icon fill='white' name='filter_list' size={21} />
            </Wrapper>

            {isOpenFilterWindow && (
                <Portal triggerRef={filterRef} isOpen={isOpenFilterWindow} customTop={10} onClose={() => setIsOpenFilterWindow(false)}>
                    <Wrapper
                        ref={filterWindowRef}
                        flexbox
                        column
                        bgColor='background.07'
                        corner='default'
                        padding='0.75rem 0.5rem'
                        zIndex='1'
                        height='fit-content'
                        maxHeight='250px'
                        width='200px'
                        gap
                    >
                        <Input icon='search' value={filterInputValue} onChange={(event) => setFilterInputValue(event.target.value)} />

                        <Wrapper flexbox column gap height='100%' style={{ overflowY: 'scroll' }}>
                            <Wrapper
                                flexbox
                                align='center'
                                gap
                                height='20px'
                                style={{ lineHeight: '16px' }}
                                onClick={() => handleSelectAll(column.key, distinctValues)}
                            >
                                <Wrapper
                                    bgColor='background.01'
                                    flexbox
                                    width='18px'
                                    height='18px'
                                    border='1px solid #FFFFFF33'
                                    corner='default'
                                    center
                                >
                                    {isAllSelected && <Icon size={15} name='done' fill='avel' />}
                                </Wrapper>
                                <Text regular width='70%' fontSize='nano' truncate>
                                    Selecionar todos
                                </Text>
                            </Wrapper>

                            {distinctValues.map((data, filtIdx) => (
                                <Wrapper
                                    key={filtIdx}
                                    flexbox
                                    align='center'
                                    gap
                                    height='20px'
                                    style={{ lineHeight: '16px' }}
                                    onClick={() => {
                                        setCurrentPaginationPage(0);
                                        handleFilterOptions(data, column.key);
                                    }}
                                >
                                    <Wrapper
                                        bgColor='background.01'
                                        flexbox
                                        width='18px'
                                        height='18px'
                                        border='1px solid #FFFFFF33'
                                        corner='default'
                                        center
                                    >
                                        {state?.filterOptions?.filters?.[column.key]?.some((item) => item === data) && (
                                            <Icon size={15} name='done' fill='avel' />
                                        )}
                                    </Wrapper>
                                    <Text regular width='70%' fontSize='nano' truncate>
                                        {data}
                                    </Text>
                                </Wrapper>
                            ))}
                        </Wrapper>
                    </Wrapper>
                </Portal>
            )}
        </Wrapper>
    );
};

export default FilterWindow;
