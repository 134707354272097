import { Input, Wrapper } from '../../../../components';
import React, { useState, useRef, useEffect } from 'react';

function useHasFocus(ref) {
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        const onFocusIn = () => setHasFocus(true);
        const onFocusOut = () => setHasFocus(false);

        const node = ref.current;
        if (node) {
            node.addEventListener('focusin', onFocusIn);
            node.addEventListener('focusout', onFocusOut);

            return () => {
                node.removeEventListener('focusin', onFocusIn);
                node.removeEventListener('focusout', onFocusOut);
            };
        }
    }, [ref]);

    return hasFocus;
}

const SearchInput = ({
    dropdown = false,
    delay = 300,
    searchFunction = () => {},
    minimumInputLength = 3,
    displayFields = { id: null, primaryInfo: { key: null, display: null }, secondaryInfo: { key: null, display: null } },
    ...props
}) => {
    const [inputValue, setInputValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const parentRef = useRef();
    const parentHasFocus = useHasFocus(parentRef);

    useEffect(() => {
        const timerId = setTimeout(async () => {
            if (inputValue.length >= minimumInputLength) {
                setDebouncedValue(await searchFunction(inputValue));
            } else {
                setDebouncedValue(await searchFunction('')); // Clear the previous results
            }
        }, delay);

        return () => {
            clearTimeout(timerId);
        };
    }, [inputValue]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (parentRef.current && !parentRef.current.contains(e.target) && !parentHasFocus) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [parentHasFocus]);

    const dropdownMouseDownRef = useRef(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setIsDropdownVisible(true); // Show dropdown whenever there's an input
    };

    const handleInputBlur = () => {
        if (!dropdownMouseDownRef.current) {
            setIsDropdownVisible(false);
        }
    };

    const handleInputFocus = () => {
        if (!dropdownMouseDownRef.current) {
            setIsDropdownVisible(true);
        }
    };

    const handleDropdownMouseDown = () => {
        dropdownMouseDownRef.current = true;
    };

    const handleDropdownMouseUp = () => {
        dropdownMouseDownRef.current = false;
    };

    const handleOnClick = (item) => {
        if (!dropdown.onClick) return;
        dropdown.onClick(item);
        setInputValue(''); // Reset input value
        setDebouncedValue([]); // Reset debounced value
        setIsDropdownVisible(false); // Hide dropdown
    };

    return (
        <Wrapper position='relative' ref={parentRef}>
            <Input
                value={props.selectedValue || inputValue}
                fontSize='0.8rem'
                placeholder={`Pesquisar${props.searchObject ? ` ${props.searchObject}` : ''}...`}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                onClear={() => {
                    setInputValue('');
                    props.onClearValue && props.onClearValue();
                }}
                {...props}
            ></Input>
            {dropdown && isDropdownVisible && debouncedValue?.length ? (
                <Wrapper
                    onMouseDown={handleDropdownMouseDown}
                    onMouseUp={handleDropdownMouseUp}
                    position='absolute'
                    borderRadius='0.25rem'
                    zIndex='2'
                    bgColor='background.04'
                    width='max-content'
                    flexbox
                    column
                    style={{ gap: '0.25rem' }}
                    fontSize='0.875rem'
                >
                    <table className='tw-table-new tw-table-new-hover'>
                        <tbody>
                            {debouncedValue.map((item) => (
                                <tr>
                                    <td>
                                        <Wrapper
                                            flexbox
                                            column
                                            key={item[displayFields.id]}
                                            onClick={() => handleOnClick(item)}
                                            padding='0.3rem'
                                            gap='small'
                                            width='14rem'
                                        >
                                            <Wrapper fontSize='tiny'>
                                                {displayFields.primaryInfo?.display
                                                    ? `${item[displayFields.primaryInfo?.key]}`
                                                    : item[displayFields?.primaryInfo?.key]}
                                            </Wrapper>
                                            <Wrapper opacity='50%' fontSize='mini'>
                                                {displayFields?.secondaryInfo?.display
                                                    ? `${displayFields?.secondaryInfo?.display}: ${item[displayFields?.secondaryInfo?.key]}`
                                                    : item[displayFields?.secondaryInfo?.key]}
                                            </Wrapper>
                                        </Wrapper>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Wrapper>
            ) : null}
        </Wrapper>
    );
};

export default SearchInput;
