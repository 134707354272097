import React, { useContext, useEffect, useState } from 'react';
import { Icon, Modal, Select, Text, Wrapper } from '../../../components';
import { BASE_URL, LAMBDA_URL, MISC_IMAGES } from '../../../Enviroment';
import { getFallback } from '../../../assets/services/apis/api';
import { colors } from '../../../assets/theme';
import { SearchInput } from '..';
import { AppContext } from '../../../Context';

const CreateGeneralGoogleMeetingModal = ({ isOpen, closeModal, selectedUser }) => {
    const [selectOptions, setSelectOptions] = useState();
    const [selectedTypeOptions, setSelectedTypeOptions] = useState({ key: null, name: 'Selecione o tipo de reunião' });
    const [selectDetail, setSelectDetail] = useState({ key: null, name: 'Selecione o tipo de atividade' });
    const [customerOrProspect, setCustomerOrProspect] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { globalContext } = useContext(AppContext);
    const requestUserInfo = globalContext?.user;

    const typeOptions = [
        { key: 0, subKey: 'prospect', name: 'Prospect' },
        { key: 1, subKey: 'customer', name: 'Cliente' },
    ];

    const searchDisplayFields = {
        id: 'id',
        primaryInfo: selectedTypeOptions?.key === 1 ? { key: 'name', display: 'Nome' } : { key: 'person_name', display: 'Nome' },
        secondaryInfo: selectedTypeOptions?.key === 1 ? { key: 'id', display: 'Customer Code' } : { key: 'deal_id', display: 'Deal id' },
    };

    const isReadyToCreateMeeting = (selectedTypeOptions?.key === 1 || (selectedTypeOptions?.key === 0 && selectDetail)) && customerOrProspect;

    async function getActivityOptionsByUser() {
        const queryParams = { id: selectedUser.id };

        const url = new URL(`${BASE_URL}/commercial/customers/portfolio/activity/options`);
        url.search = new URLSearchParams(queryParams);

        const res = await getFallback(url, false, 'only-warning');
        const data = res.data;

        const filteredOptions = data.flatMap((item) => item.options)?.filter((option) => option.typeId === 2 && option.id !== 3 && option.id !== 14);
        const uniqueOptions = filteredOptions.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));

        setSelectOptions(uniqueOptions);
    }

    const getProspectOrCustomer = async (searchData) => {
        if (searchData) {
            setIsLoading(true);

            const queryParams = {
                type: selectedTypeOptions?.subKey,
                assCode: selectedTypeOptions?.key === 1 ? requestUserInfo?.assCode : requestUserInfo?.pipeId,
                data: searchData,
            };

            const url = new URL(`${BASE_URL}/system/search`);
            url.search = new URLSearchParams(queryParams);

            const response = await getFallback(url, false, 'only-warning');

            setIsLoading(false);
            return response?.data;
        }
    };

    const handleGoogleMeeting = async (event) => {
        event.stopPropagation();

        const queryParams = {
            userId: selectedUser.id,
            email: selectedUser.email,
            sourceType: selectedTypeOptions?.key,
            sourceId: selectedTypeOptions?.key === 1 ? customerOrProspect?.id : customerOrProspect?.deal_id,
            activityDetail: selectedTypeOptions?.key === 1 ? 3 : selectDetail?.id,
        };

        const url = new URL(`${LAMBDA_URL}/create-meeting`);
        url.search = new URLSearchParams(queryParams).toString();

        const response = await getFallback(url, true, true, true, {
            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
            'x-api-key': process.env.REACT_APP_LAMBDA_KEY,
        });

        if (response.ok) {
            window.open(response.data.link).focus();

            closeModal();
        }
    };

    useEffect(() => {
        if (selectedUser) {
            getActivityOptionsByUser();
        }
    }, [selectedUser]);

    useEffect(() => {
        if (!isOpen || selectedTypeOptions === undefined) {
            setSelectedTypeOptions(undefined);
            setSelectDetail(undefined);
            setCustomerOrProspect('');
        }
    }, [isOpen, selectedTypeOptions]);

    return (
        <Modal
            open={isOpen}
            close={closeModal}
            title={'Meet'}
            titleColor={'background.05'}
            contentStyle={{ background: colors.getColor('background.02') }}
        >
            <Wrapper flexbox column center gap width='410px' padding='0 0 2rem 0' bgColor='background.02'>
                <Wrapper flexbox column center gap padding='0 0 2rem 0'>
                    <Wrapper flexbox column center gap padding='0 0 1.5rem 0'>
                        <img src={`${MISC_IMAGES}/google-meet-logo.png`} width='135px' height='90px' style={{ objectFit: 'cover' }} />
                        <Text color='text.70'>Iniciando chamada...</Text>

                        <Text color='text.60' fontSize='mini'>
                            Preencha um dos campos para poder entrar na chamada.
                        </Text>
                    </Wrapper>

                    <Select
                        fontSize='tiny'
                        width='230px'
                        options={typeOptions}
                        withSearch
                        selectedValue={selectedTypeOptions}
                        onChangeOption={(id, value) => setSelectedTypeOptions(value)}
                    />

                    {selectedTypeOptions && (
                        <SearchInput
                            style={{ fontSize: '12.8px' }}
                            isLoading={isLoading}
                            width='228px'
                            height='35px'
                            bgColor='#252525'
                            searchFunction={(a) => getProspectOrCustomer(a)}
                            searchObject={`por ${selectedTypeOptions?.key === 1 ? 'Código do Cliente' : 'Deal ID'}`}
                            selectedValue={customerOrProspect?.person_name || customerOrProspect?.name || ''}
                            onClearValue={() => setCustomerOrProspect(null)}
                            dropdown={{ onClick: (customer) => setCustomerOrProspect(customer) }}
                            displayFields={searchDisplayFields}
                        />
                    )}

                    {selectedTypeOptions?.key === 0 && (
                        <Select
                            fontSize='tiny'
                            width='230px'
                            options={selectOptions}
                            selectedValue={selectDetail}
                            onChangeOption={(id, value) => setSelectDetail(value)}
                        />
                    )}
                </Wrapper>

                <Wrapper
                    flexbox
                    center
                    width='fit-content'
                    padding='0.5rem 0.75rem'
                    corner='default'
                    opacity={isReadyToCreateMeeting ? '1' : '0.75'}
                    gap
                    bgColor={isReadyToCreateMeeting ? 'info' : '#3B3B3B'}
                    onClick={(e) => isReadyToCreateMeeting && handleGoogleMeeting(e)}
                >
                    <Icon fill='white' name='video_call' size={18} />
                    <Text fontSize='small'>Nova reunião</Text>
                </Wrapper>
            </Wrapper>
        </Modal>
    );
};

export default CreateGeneralGoogleMeetingModal;
