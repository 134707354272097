import EventBus from '../../Events/EventBus';
import { v4 as uuidv4 } from 'uuid';

const Toast = ({ type = 'send-http-response', removeType = 'dismiss-http-response', message, status = 200, uuid = uuidv4(), duration = 3000 }) => {
    EventBus.emit(type, {
        message,
        uuid,
        status,
    });

    setTimeout(() => {
        EventBus.emit(removeType, uuid);
    }, duration);
};

export default Toast;
