const BASE32_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';

export function decodeBase32(encoded) {
    const alphabet = BASE32_ALPHABET;
    let bits = '';
    let decoded = '';

    encoded = encoded.replace(/=+$/, '');

    for (let i = 0; i < encoded.length; i++) {
        const val = alphabet.indexOf(encoded.charAt(i).toUpperCase());
        if (val === -1) {
            throw new Error(`Caractere inválido na posição ${i}: ${encoded.charAt(i)}`);
        }
        bits += val.toString(2).padStart(5, '0');
    }

    for (let i = 0; i + 8 <= bits.length; i += 8) {
        const byte = bits.substr(i, 8);
        decoded += String.fromCharCode(parseInt(byte, 2));
    }

    return decoded;
}
