import { useEffect } from 'react';

function useOutsideClick(refs, callback) {
    useEffect(() => {
        const elements = Array.isArray(refs) ? refs : [refs];

        const isEventOutside = (event) => {
            return elements.every((ref) => ref.current && !ref.current.contains(event.target));
        };

        const handleClickOrTouch = (event) => {
            if (isEventOutside(event)) {
                callback();
            }
        };

        window.addEventListener('click', handleClickOrTouch, false);
        window.addEventListener('touchstart', handleClickOrTouch, false);

        return () => {
            window.removeEventListener('click', handleClickOrTouch, false);
            window.removeEventListener('touchstart', handleClickOrTouch, false);
        };
    }, [callback, refs]);
}

export default useOutsideClick;
